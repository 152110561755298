// al final de tot hi ha un export
function llista_ways() {
var ways = [
{
  "type": "way",
  "id": 87021454,
  "center": {
    "lat": 42.2562872,
    "lon": 2.8042385
  },
  "tags": {
    "addr:city": "Lladó",
    "building": "house",
    "building:levels": "1",
    "name": "Ca la Bruixa"
  }
},
{
  "type": "way",
  "id": 182939130,
  "center": {
    "lat": 41.2888830,
    "lon": 0.8696242
  },
  "tags": {
    "name": "Barranc de la Bruixa",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 249401785,
  "center": {
    "lat": 41.3836874,
    "lon": 2.1306401
  },
  "tags": {
    "addr:city": "Barcelona",
    "addr:housenumber": "175",
    "addr:street": "Carrer de Joan Güell",
    "leisure": "park",
    "name": "Jardins de Can Bruixa",
    "source": "Ajuntament de Barcelona - CartoBCN (@2013-11-10)"
  }
},
{
  "type": "way",
  "id": 264556499,
  "center": {
    "lat": 41.5970570,
    "lon": 1.8706865
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de la Bruixa",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 363594648,
  "center": {
    "lat": 42.1677958,
    "lon": 1.7457591
  },
  "tags": {
    "name": "Cingles de la Bruixa",
    "natural": "cliff"
  }
},
{
  "type": "way",
  "id": 663217775,
  "center": {
    "lat": 41.5593243,
    "lon": 2.4664481
  },
  "tags": {
    "intermittent": "yes",
    "layer": "-1",
    "name": "Barranc de la Bruixa",
    "tunnel": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 663217776,
  "center": {
    "lat": 41.5603881,
    "lon": 2.4655386
  },
  "tags": {
    "intermittent": "yes",
    "name": "Barranc de la Bruixa",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 670883643,
  "center": {
    "lat": 41.2643637,
    "lon": 1.8303991
  },
  "tags": {
    "intermittent": "yes",
    "name": "Fondo de la Bruixa",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 882445081,
  "center": {
    "lat": 41.3100820,
    "lon": 1.6406468
  },
  "tags": {
    "name": "Torrent de la Bruixa",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 383270077,
  "center": {
    "lat": 40.8348712,
    "lon": 0.4988656
  },
  "tags": {
    "highway": "unclassified",
    "name": "Camí del Barranc de les Bruixes",
    "oneway": "no"
  }
},
{
  "type": "way",
  "id": 84665989,
  "center": {
    "lat": 41.6622873,
    "lon": 0.5505133
  },
  "tags": {
    "highway": "unclassified",
    "maxspeed": "50",
    "name": "Carretera de les Bruixes"
  }
},
{
  "type": "way",
  "id": 194665179,
  "center": {
    "lat": 41.6544770,
    "lon": 0.5273701
  },
  "tags": {
    "highway": "unclassified",
    "name": "Camí de les Bruixes"
  }
},
{
  "type": "way",
  "id": 295293800,
  "center": {
    "lat": 41.6575665,
    "lon": 0.5482601
  },
  "tags": {
    "landuse": "residential",
    "name": "les Bruixes",
    "place": "neighbourhood"
  }
},
{
  "type": "way",
  "id": 580178700,
  "center": {
    "lat": 41.6616864,
    "lon": 0.5467154
  },
  "tags": {
    "highway": "track",
    "name": "Camí de les bruixes"
  }
},
{
  "type": "way",
  "id": 220874661,
  "center": {
    "lat": 41.2878215,
    "lon": 1.6599652
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 383834524,
  "center": {
    "lat": 41.1416278,
    "lon": 1.3734201
  },
  "tags": {
    "highway": "residential",
    "name": "Camí de les Bruixes",
    "source": "Bing Aerial Maps 2011"
  }
},
{
  "type": "way",
  "id": 800864152,
  "center": {
    "lat": 41.1855910,
    "lon": 1.2157895
  },
  "tags": {
    "name": "Barranc de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 220874661,
  "center": {
    "lat": 41.2878215,
    "lon": 1.6599652
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 383834524,
  "center": {
    "lat": 41.1416278,
    "lon": 1.3734201
  },
  "tags": {
    "highway": "residential",
    "name": "Camí de les Bruixes",
    "source": "Bing Aerial Maps 2011"
  }
},
{
  "type": "way",
  "id": 800864152,
  "center": {
    "lat": 41.1855910,
    "lon": 1.2157895
  },
  "tags": {
    "name": "Barranc de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 31790110,
  "center": {
    "lat": 41.5728944,
    "lon": 1.6017200
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 222319193,
  "center": {
    "lat": 41.6668453,
    "lon": 1.2720738
  },
  "tags": {
    "highway": "pedestrian",
    "name": "Carreró de les Bruixes",
    "tunnel": "yes"
  }
},
{
  "type": "way",
  "id": 303936067,
  "center": {
    "lat": 41.5337087,
    "lon": 1.2359920
  },
  "tags": {
    "area": "yes",
    "name": "Plaça de les Bruixes"
  }
},
{
  "type": "way",
  "id": 560108371,
  "center": {
    "lat": 41.5679234,
    "lon": 1.5938347
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 796878418,
  "center": {
    "lat": 41.6448585,
    "lon": 1.5887067
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de la Plaça de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 35806690,
  "center": {
    "lat": 41.4179665,
    "lon": 2.0205047
  },
  "tags": {
    "leisure": "park",
    "name": "Plaça de les Bruixes"
  }
},
{
  "type": "way",
  "id": 167403992,
  "center": {
    "lat": 41.6327695,
    "lon": 2.0098135
  },
  "tags": {
    "name": "Canal de les Bruixes",
    "natural": "heights"
  }
},
{
  "type": "way",
  "id": 318502763,
  "center": {
    "lat": 41.6328630,
    "lon": 2.0102609
  },
  "tags": {
    "highway": "path",
    "name": "Canal de les Bruixes",
    "sac_scale": "mountain_hiking",
    "trail_visibility": "intermediate"
  }
},
{
  "type": "way",
  "id": 671280485,
  "center": {
    "lat": 41.6914793,
    "lon": 2.4301090
  },
  "tags": {
    "intermittent": "yes",
    "name": "Sot de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 752675687,
  "center": {
    "lat": 41.4674035,
    "lon": 2.2067643
  },
  "tags": {
    "ford": "yes",
    "highway": "path",
    "intermittent": "yes",
    "name": "Torrent de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 172666729,
  "center": {
    "lat": 41.5570695,
    "lon": 2.4858153
  },
  "tags": {
    "ford": "yes",
    "highway": "path",
    "intermittent": "yes",
    "name": "Torrent de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 355281579,
  "center": {
    "lat": 41.7848872,
    "lon": 2.5406833
  },
  "tags": {
    "historic": "castle",
    "name": "Torre de les Bruixes",
    "wikipedia": "ca:Torre de les Bruixes"
  }
},
{
  "type": "way",
  "id": 663217786,
  "center": {
    "lat": 41.5536092,
    "lon": 2.4879847
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de les Bruixes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 75662276,
  "center": {
    "lat": 42.2679186,
    "lon": 2.9609520
  },
  "tags": {
    "highway": "footway",
    "name": "Corriol de les Bruixes",
    "name:1508": "carreró d'en Maçó",
    "name:1979": "Corriol de les Bruixes",
    "source": "Ajuntament de Figueres",
    "source_ref": "PGOU",
    "start_date": "mid C14",
    "width": "2"
  }
},
{
  "type": "way",
  "id": 330145613,
  "center": {
    "lat": 42.1921841,
    "lon": 2.4995591
  },
  "tags": {
    "highway": "residential",
    "name": "Camí de les Bruixes",
    "surface": "ground",
    "tracktype": "grade1",
    "width": "2"
  }
},
{
  "type": "way",
  "id": 421606952,
  "center": {
    "lat": 42.0432416,
    "lon": 3.1241018
  },
  "tags": {
    "building": "yes",
    "historic": "yes",
    "man_made": "tower",
    "name": "Torre de les Bruixes",
    "tower:type": "defensive"
  }
},
{
  "type": "way",
  "id": 875058093,
  "center": {
    "lat": 41.9448542,
    "lon": 2.8452195
  },
  "tags": {
    "highway": "unclassified",
    "name": "Camí del Pic de les Bruixes"
  }
},
{
  "type": "way",
  "id": 177868781,
  "center": {
    "lat": 41.6023535,
    "lon": 1.8208948
  },
  "tags": {
    "name": "Paret dels Diables",
    "natural": "cliff",
    "source": "Institut Cartogràfic de Catalunya"
  }
},
{
  "type": "way",
  "id": 570973162,
  "center": {
    "lat": 41.6358046,
    "lon": 2.2974207
  },
  "tags": {
    "building": "shed",
    "name": "Caseta dels Diables de Les Franqueses"
  }
},
{
  "type": "way",
  "id": 654075065,
  "center": {
    "lat": 41.5336168,
    "lon": 2.0794808
  },
  "tags": {
    "highway": "pedestrian",
    "name": "Carreró dels diables",
    "surface": "concrete"
  }
},
{
  "type": "way",
  "id": 287262976,
  "center": {
    "lat": 41.3059145,
    "lon": 2.0476902
  },
  "tags": {
    "highway": "track",
    "name": "Camí de can Dimoni"
  }
},
{
  "type": "way",
  "id": 385450057,
  "center": {
    "lat": 41.4759174,
    "lon": 2.2289384
  },
  "tags": {
    "amenity": "restaurant",
    "building": "retail",
    "building:levels": "2",
    "building:levels:underground": "1",
    "name": "Cal Dimoni",
    "wikidata": "Q20985074",
    "wikipedia": "ca:Cal Dimoni"
  }
},
{
  "type": "way",
  "id": 385450067,
  "center": {
    "lat": 41.4755576,
    "lon": 2.2298129
  },
  "tags": {
    "highway": "track",
    "name": "camí de Cal Dimoni",
    "name:wikipedia": "ca:Cal Dimoni",
    "sorting_name": "Cal Dimoni, camí de"
  }
},
{
  "type": "way",
  "id": 774633267,
  "center": {
    "lat": 41.9771527,
    "lon": 2.8101881
  },
  "tags": {
    "access": "no",
    "bridge": "yes",
    "foot": "yes",
    "highway": "residential",
    "layer": "1",
    "motorcar": "no",
    "name": "Pont del Dimoni",
    "wikidata": "Q21886114",
    "wikipedia": "ca:Pont del Dimoni"
  }
},
{
  "type": "way",
  "id": 93951838,
  "center": {
    "lat": 41.7240889,
    "lon": 2.9388889
  },
  "tags": {
    "highway": "service",
    "name": "Carrer de l'Infern d'en Caixa"
  }
},
{
  "type": "way",
  "id": 151235441,
  "center": {
    "lat": 41.2562417,
    "lon": 1.9022542
  },
  "tags": {
    "highway": "residential",
    "name": "Passatge del Pou de l'Infern"
  }
},
{
  "type": "way",
  "id": 177869197,
  "center": {
    "lat": 41.5298067,
    "lon": 2.3738625
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de l'Infern d'en Parera"
  }
},
{
  "type": "way",
  "id": 193018358,
  "center": {
    "lat": 41.6737969,
    "lon": 2.2031405
  },
  "tags": {
    "highway": "residential",
    "lit": "yes",
    "name": "Carrer de la Font d'Infern",
    "sidewalk": "both",
    "surface": "concrete"
  }
},
{
  "type": "way",
  "id": 196750157,
  "center": {
    "lat": 41.7063755,
    "lon": 1.7478879
  },
  "tags": {
    "name": "Torrent de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 273732291,
  "center": {
    "lat": 41.6797363,
    "lon": 1.9479768
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent del Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 291324691,
  "center": {
    "lat": 41.5289930,
    "lon": 2.3753298
  },
  "tags": {
    "highway": "residential",
    "maxspeed": "30",
    "name": "Carrer de l'Infern d'en Parera",
    "source": "Yahoo WMS",
    "surface": "asphalt",
    "width": "4"
  }
},
{
  "type": "way",
  "id": 387808366,
  "center": {
    "lat": 42.1122934,
    "lon": 2.3876836
  },
  "tags": {
    "name": "Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 394958066,
  "center": {
    "lat": 42.0119426,
    "lon": 2.1853206
  },
  "tags": {
    "name": "torrent de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 410352702,
  "center": {
    "lat": 42.2796004,
    "lon": 1.0493164
  },
  "tags": {
    "name": "Barranc de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 424237071,
  "center": {
    "lat": 41.9308305,
    "lon": 2.2225145
  },
  "tags": {
    "name": "l'Infern",
    "place": "isolated_dwelling",
    "source": "POUM Vic"
  }
},
{
  "type": "way",
  "id": 483944890,
  "center": {
    "lat": 41.4585569,
    "lon": 2.1289917
  },
  "tags": {
    "name": "Torrent de l'Infern Gran",
    "seasonal": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 579317390,
  "center": {
    "lat": 41.6398840,
    "lon": 2.5634277
  },
  "tags": {
    "intermittent": "yes",
    "name": "Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 608972978,
  "center": {
    "lat": 42.0227590,
    "lon": 2.0044401
  },
  "tags": {
    "intermittent": "yes",
    "name": "Clot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 618623732,
  "center": {
    "lat": 42.0680102,
    "lon": 2.3238474
  },
  "tags": {
    "intermittent": "yes",
    "name": "Cot de l'Infernot",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 625992782,
  "center": {
    "lat": 41.7599455,
    "lon": 2.4355730
  },
  "tags": {
    "intermittent": "yes",
    "layer": "-1",
    "name": "Torrent de l'Infern",
    "tunnel": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 625992783,
  "center": {
    "lat": 41.7616659,
    "lon": 2.4359391
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 625992784,
  "center": {
    "lat": 41.7633730,
    "lon": 2.4364983
  },
  "tags": {
    "intermittent": "yes",
    "layer": "-1",
    "name": "Torrent de l'Infern",
    "tunnel": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 625992785,
  "center": {
    "lat": 41.7671653,
    "lon": 2.4383713
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 625992786,
  "center": {
    "lat": 41.7571613,
    "lon": 2.4337479
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 630270996,
  "center": {
    "lat": 41.7659091,
    "lon": 2.2993817
  },
  "tags": {
    "intermittent": "yes",
    "name": "Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 630270997,
  "center": {
    "lat": 41.7666880,
    "lon": 2.2863247
  },
  "tags": {
    "intermittent": "yes",
    "name": "Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 670883646,
  "center": {
    "lat": 41.2785932,
    "lon": 1.8200093
  },
  "tags": {
    "name": "Serreta del Fondo de l'Infern",
    "natural": "ridge"
  }
},
{
  "type": "way",
  "id": 670883647,
  "center": {
    "lat": 41.2781227,
    "lon": 1.8219996
  },
  "tags": {
    "intermittent": "yes",
    "name": "Fondo de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 671162910,
  "center": {
    "lat": 41.6754776,
    "lon": 2.3881268
  },
  "tags": {
    "intermittent": "yes",
    "layer": "-1",
    "name": "Torrent del Mal Infern",
    "tunnel": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 671162912,
  "center": {
    "lat": 41.6712585,
    "lon": 2.3891688
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent del Mal Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 676552518,
  "center": {
    "lat": 42.3659672,
    "lon": 1.4064657
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer Inferns"
  }
},
{
  "type": "way",
  "id": 737891429,
  "center": {
    "lat": 41.0084197,
    "lon": 0.4237767
  },
  "tags": {
    "name": "Barranc de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 781068650,
  "center": {
    "lat": 41.6847713,
    "lon": 1.9250093
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent del Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 783118490,
  "center": {
    "lat": 41.6849499,
    "lon": 1.9278538
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent del Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 791410912,
  "center": {
    "lat": 42.2386608,
    "lon": 2.6108320
  },
  "tags": {
    "intermittent": "yes",
    "name": "Clot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 791410913,
  "center": {
    "lat": 42.2418848,
    "lon": 2.6133294
  },
  "tags": {
    "intermittent": "yes",
    "layer": "-1",
    "name": "Clot de l'Infern",
    "tunnel": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 791410920,
  "center": {
    "lat": 42.2505938,
    "lon": 2.6163522
  },
  "tags": {
    "intermittent": "yes",
    "name": "Clot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 797536227,
  "center": {
    "lat": 42.3128176,
    "lon": 2.2276060
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent del Vell Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 820505819,
  "center": {
    "lat": 41.9681410,
    "lon": 3.2112503
  },
  "tags": {
    "highway": "track",
    "name": "Carrer Cova de l'Infern"
  }
},
{
  "type": "way",
  "id": 868805829,
  "center": {
    "lat": 41.6848370,
    "lon": 1.9292474
  },
  "tags": {
    "highway": "track",
    "intermittent": "yes",
    "name": "Torrent del Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 868805830,
  "center": {
    "lat": 41.6854225,
    "lon": 1.9265914
  },
  "tags": {
    "highway": "track",
    "intermittent": "yes",
    "name": "Torrent del Sot de l'Infern",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 103337837,
  "center": {
    "lat": 41.6217021,
    "lon": 2.0593202
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer de la Cova del Drac",
    "surface": "asphalt"
  }
},
{
  "type": "way",
  "id": 119208534,
  "center": {
    "lat": 41.2187066,
    "lon": 1.7197009
  },
  "tags": {
    "addr:housenumber": "6-8",
    "addr:postcode": "08800",
    "addr:street": "Carrer del Canigó",
    "amenity": "kindergarten",
    "email": "a8049245@xtec.cat",
    "name": "Llar d'infants El Drac",
    "operator": "Generalitat de Catalunya",
    "phone": "+34 938154104"
  }
},
{
  "type": "way",
  "id": 146660501,
  "center": {
    "lat": 41.4700464,
    "lon": 2.1823811
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Drac",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 155212922,
  "center": {
    "lat": 41.9964069,
    "lon": 1.5216515
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Drac"
  }
},
{
  "type": "way",
  "id": 269087484,
  "center": {
    "lat": 41.2341510,
    "lon": 1.7246359
  },
  "tags": {
    "highway": "footway",
    "name": "Carrer del Drac"
  }
},
{
  "type": "way",
  "id": 269087486,
  "center": {
    "lat": 41.2342951,
    "lon": 1.7261229
  },
  "tags": {
    "highway": "footway",
    "name": "Carrer del Drac"
  }
},
{
  "type": "way",
  "id": 295826465,
  "center": {
    "lat": 41.4137091,
    "lon": 2.1528200
  },
  "tags": {
    "artist_name": "Antoni Gaudí;Josep Maria Jujol",
    "artwork_type": "sculpture",
    "description": "A crawling mosaic salamander",
    "name": "Drac",
    "name:en": "Dragon",
    "source": "Survey of 2014-07-31",
    "tourism": "artwork",
    "wheelchair": "no"
  }
},
{
  "type": "way",
  "id": 378953448,
  "center": {
    "lat": 41.5827337,
    "lon": 2.5100365
  },
  "tags": {
    "highway": "track",
    "name": "Carrer del Drac",
    "tracktype": "grade2"
  }
},
{
  "type": "way",
  "id": 378953455,
  "center": {
    "lat": 41.5840035,
    "lon": 2.5109792
  },
  "nodes": [
    3823201562,
    3823201560
  ],
  "tags": {
    "highway": "residential",
    "name": "Carrer del Drac"
  }
},
{
  "type": "way",
  "id": 395669526,
  "center": {
    "lat": 41.5790253,
    "lon": 2.0306494
  },
  "tags": {
    "highway": "pedestrian",
    "name": "carrer de la Cova del Drac"
  }
},
{
  "type": "way",
  "id": 461179052,
  "center": {
    "lat": 41.5941623,
    "lon": 2.5714226
  },
  "tags": {
    "amenity": "school",
    "name": "Escola Turó del Drac"
  }
},
{
  "type": "way",
  "id": 528662080,
  "center": {
    "lat": 41.9960667,
    "lon": 1.5211178
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Drac"
  }
},
{
  "type": "way",
  "id": 560536181,
  "center": {
    "lat": 41.3786707,
    "lon": 2.1416876
  },
  "tags": {
    "artist_name": "Andrès Nagel",
    "artwork_type": "sculpture",
    "author": "Andrés Nagel",
    "building": "yes",
    "name": "El Drac",
    "name:en": "The Dragon",
    "name:es": "El Dragón",
    "tourism": "artwork",
    "year_of_construction": "1987"
  }
},
{
  "type": "way",
  "id": 638796600,
  "center": {
    "lat": 41.4706828,
    "lon": 2.1823203
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Drac",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 307457924,
  "center": {
    "lat": 41.6180406,
    "lon": 1.9681983
  },
  "tags": {
    "name": "Torrent Maleït",
    "seasonal": "yes",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 944029186,
  "center": {
    "lat": 42.31780,
    "lon": 3.32079
  },
  "tags": {
    "name": "Costa de s'Infern",
    "natural": "coastline"
  }
},
{
  "type": "way",
  "id": 30253611,
  "center": {
    "lat": 41.1459217,
    "lon": 1.2396920
  },
  "tags": {
    "highway": "unclassified",
    "name": "Camí del Parc del Pont del Diable",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 75786554,
  "center": {
    "lat": 41.1350251,
    "lon": 1.2534633
  },
  "tags": {
    "highway": "residential",
    "junction": "roundabout",
    "lanes": "1",
    "lit": "yes",
    "name": "Camí Pont del Diable",
    "sidewalk": "none",
    "surface": "asphalt"
  }
},
{
  "type": "way",
  "id": 75855004,
  "center": {
    "lat": 41.1480972,
    "lon": 1.1034173
  },
  "tags": {
    "highway": "residential",
    "name": "Carrer del Ball de Diables"
  }
},
{
  "type": "way",
  "id": 152534575,
  "center": {
    "lat": 41.3487736,
    "lon": 1.6899849
  },
  "tags": {
    "highway": "tertiary",
    "name": "Avinguda del Pla del Diable",
    "oneway": "yes"
  }
},
{
  "type": "way",
  "id": 182395267,
  "center": {
    "lat": 41.6012096,
    "lon": 1.7764662
  },
  "tags": {
    "intermittent": "yes",
    "name": "Torrent de la Diablera",
    "waterway": "stream"
  }
},
{
  "type": "way",
  "id": 880985660,
  "center": {
    "lat": 41.4285880,
    "lon": 1.5128930
  },
  "tags": {
    "alt_name": "el Diable de Comellar",
    "name": "Casa de Colònies Cal Diable",
    "place": "isolated_dwelling",
    "tourism": "hostel"
  }
},
{
  "type": "way",
  "id": 370314115,
  "center": {
    "lat": 41.9196229,
    "lon": 1.6848041
  },
  "tags": {
    "alt_name": "Pont Trencat",
    "heritage": "2",
    "historic": "bridge",
    "layer": "1",
    "man_made": "bridge",
    "name": "Pont del Diable",
    "start_date": "C15",
    "wikidata": "Q1337325",
    "wikipedia": "ca:Pont del Diable (Cardona)"
  }
},
{
  "type": "way",
  "id": 113754555,
  "center": {
    "lat": 41.5673517,
    "lon": 2.2275805
  },
  "tags": {
    "highway": "residential",
    "name": "Avinguda Pedra del Diable"
  }
},
{
  "type": "way",
  "id": 193837765,
  "center": {
    "lat": 41.5685984,
    "lon": 2.2195775
  },
  "tags": {
    "bicycle": "designated",
    "foot": "designated",
    "highway": "path",
    "name": "Camí de la Pedra del Diable",
    "segregated": "no"
  }
},
{
  "type": "way",
  "id": 288867491,
  "center": {
    "lat": 41.5671397,
    "lon": 2.2304585
  },
  "tags": {
    "bench": "yes",
    "bus": "yes",
    "covered": "yes",
    "highway": "platform",
    "name": "Av. de Catalunya - Pedra del Diable",
    "public_transport": "platform",
    "shelter": "yes"
  }
},
{
  "type": "way",
  "id": 854234719,
  "center": {
    "lat": 41.5679323,
    "lon": 2.2201859
  },
  "tags": {
    "highway": "track",
    "name": "Camí de la Pedra del Diable"
  }
},
{
  "type": "way",
  "id": 278617156,
  "center": {
    "lat": 42.1068032,
    "lon": 1.8449370
  },
  "tags": {
    "bridge": "yes",
    "highway": "track",
    "layer": "1",
    "name": "Pont del Diable"
  }
},
{
  "type": "way",
  "id": 435449075,
  "center": {
    "lat": 42.0162215,
    "lon": 2.6892102
  },
  "tags": {
    "name": "Gorg del Diable",
    "natural": "water",
    "water": "stream_pool"
  }
},
{
  "type": "way",
  "id": 426185261,
  "center": {
    "lat": 42.21416,
    "lon": 0.90020
  },
  "tags": {
    "name": "Barranc de les Bruixes",
    "waterway": "stream"
  }
}
];
return ways;
}

export default llista_ways;