import 'ol/ol.css';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import {Icon, Style} from 'ol/style';
import {LineString, Point} from 'ol/geom';
import {getVectorContext} from 'ol/render';
import {fromLonLat} from 'ol/proj';
import {Tile as TileLayer, Vector as VectorLayer, Group as LayerGroup} from 'ol/layer';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import Overlay from 'ol/Overlay';
import {FullScreen, defaults as defaultControls} from 'ol/control';

//importació dels nodes
import llista_nodes from './nodes_combinats.js';
import llista_ways from './ways_combinats.js';

/**
 * Elements that make up the popup.
 */
var container = document.getElementById('popup');
var content = document.getElementById('popup-content');
var closer = document.getElementById('popup-closer');

/**
 * Create an overlay to anchor the popup to the map.
 */
var overlay = new Overlay({
  element: container,
  autoPan: true,
  autoPanAnimation: {
    duration: 250,
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};


var features_nodes = new Array();

let nodes = llista_nodes();
//console.log(.length);
for (let i=0; i<nodes.length; i++) {
  var txt = "";
  var obj_tags = nodes[i].tags;
  for (var key in obj_tags) {
    //console.log(key);
    //obj_tags.hasOwnProperty(key)
    //console.log(obj_tags[key]);
    switch(obj_tags[key]) {
      case "locality":
        txt = "Lloc";
        break;
      case "spring":
        txt = "Font";
        break;
      case "cave_entrance":
        txt = "Cova";
        break;
      case "peak":
        txt = "Pic";
        break;
      case "rock":
        txt = "Roca";
        break;
      case "saddle":
        txt = "Coll";
        break;
      case "cliff":
        txt = "Cinglera";
        break;
      case "valley":
        txt = "Vall";
        break;
      case "playground":
        txt = "Parc";
        break;
      case "attraction":
        txt = "Atracció";
        break;
      case "information":
        txt = "Punt informació";
        break;
      case "viewpoint":
        txt = "Mirador";
        break;
      case "waterfall":
        txt = "Cascada";
        break;
      case "shed":
        txt = "Cabana";
        break;
      case "survey_point":
        txt = "Mirador";
        break;
      case "association":
        txt = "Associació";
        break;
      case "social_centre":
        txt = "Associació";
        break;
      case "wood":
        txt = "Bosc";
        break;  
      case "water_well":
        txt = "Pou";
        break;
      case "archaeological_site":
        txt = "Lloc arqueològic";
        break;
      case "heights":
        txt = "Canal";
        break;
      case "Plaça de les Bruixes":
        txt = "Plaça";
        break;
      case "kindergarten":
        txt = "Llar d'infants";
        break; 
      case "monument":
        txt = "Monument";
        break;
      case "memorial":
        txt = "Monument";
        break;
      case "sculpture":
        txt = "Escultura";
        break; 
      case "restaurant":
        txt = "Restaurant";
        break; 
      case "school":
        txt = "Escola";
        break;
      case "cape":
        txt = "Punta, cap";
        break; 
      case "megalith":
        txt = "Megalit";
        break;
      case "motorway_junction":
        txt = "Àrea de descans";
        break;
      case "pier":
        txt = "Pont";
        break;
    }
  }

  //if (txt!="") {
    //console.log(txt);
    //break;
  if (txt=="") { //els que falten
    console.log(nodes[i].tags.name)
    console.log(nodes[i].lat)
    console.log(nodes[i].lon)
    console.log("")
  }
  var tipus=txt;
  //console.log(tipus);

  var feature_node = new Feature({
    'geometry': new Point(fromLonLat([nodes[i].lon, nodes[i].lat])),
    'name': nodes[i].tags.name,
    'tipus': tipus
  });
  features_nodes.push(feature_node)
}

var features_ways = new Array();
let ways = llista_ways();
for (let i=0; i<ways.length; i++) {
  var txt = "";
  var obj_tags = ways[i].tags;
  for (var key in obj_tags) {
    //console.log(key);
    //obj_tags.hasOwnProperty(key)
    //console.log(obj_tags[key]);
    switch(obj_tags[key]) {
      case "house":
        txt = "Casa";
        break;
      case "park":
        txt = "Parc";
        break;
      case "stream":
        txt = "Riu";
        break;
      case "cliff":
        txt = "Cinglera";
        break;
      case "heights":
        txt = "Canal";
        break;
      case "ridge":
        txt = "Serra";
        break;
      case "path":
        txt = "Camí";
        break;
      case "residential":
        txt = "Carrer";
        break;
      case "footway":
        txt = "Camí";
        break;
      case "track":
        txt = "Camí";
        break;
      case "castle":
        txt = "Castell";
        break;
      case "isolated_dwelling":
        txt = "Casa";
        break;
      case "Carrer de l\'Infern d'en Caixa":
        txt = "Carrer";
        break;     
      case "restaurant":
        txt = "Casa";
        break;
      case "Camí del Barranc de les Bruixes":
        txt = "Camí";
        break;
      case "Carretera de les Bruixes":
        txt = "Via";
        break;
      case "Camí de les Bruixes":
        txt = "Camí";
        break; 
      case "pedestrian":
        txt = "Carrer";
        break;
      case "tower":
        txt = "Torre";
        break;
      case "Plaça de les Bruixes":
        txt = "Plaça";
        break;
      case "Camí del Pic de les Bruixes":
        txt = "Camí";
        break;
      case "Caseta dels Diables de Les Franqueses":
        txt = "Associació";
        break;
      case "kindergarten":
        txt = "Llar d'infants";
        break;
      case "sculpture":
        txt = "Escultura";
        break;  
      case "school":
        txt = "Escola";
        break;
      case "coastline":
        txt = "Costa";
        break;
      case "Camí del Parc del Pont del Diable":
        txt = "Camí";
        break;
      case "Camí Pont del Diable":
        txt = "Camí";
        break;
      case "Avinguda del Pla del Diable":
        txt = "Carrer";
        break;
      case "hostel":
        txt = "Casa";
        break;
      case "bridge":
        txt = "Pont";
        break;
      case "Camí de la Pedra del Diable":
        txt = "Camí";
        break;
      case "Av. de Catalunya - Pedra del Diable":
        txt = "Carrer";
        break;
      case "Gorg del Diable":
        txt = "Gorg";
        break;
    }
  }
  //if (txt!="") {
    //console.log(txt);
    //break;
  if (txt=="") { //els que falten
    console.log(ways[i].tags.name)
    console.log(ways[i].center.lat)
    console.log(ways[i].center.lon)
    console.log("")
  }
  var tipus=txt;
  //console.log(tipus);
  var feature_way = new Feature({
    'geometry': new Point(fromLonLat([ways[i].center.lon, ways[i].center.lat])),
    'name': ways[i].tags.name,
    'tipus': tipus
  });
  features_ways.push(feature_way)
}

var iconStyle = new Style({
  image: new Icon({
    anchor: [0.5,20],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    src: 'img/logo_fire_24.png'
  })
});

var vectorSourceNodes = new VectorSource({
  features: features_nodes,
  wrapX: false,
});

var vectorNode = new VectorLayer({
  source: vectorSourceNodes,
  style: iconStyle,
});

var vectorSourceWays = new VectorSource({
  features: features_ways,
  wrapX: false,
});

var vectorWay = new VectorLayer({
  source: vectorSourceWays,
  style: iconStyle,
});

var layersOSM = new LayerGroup({
  layers: [
    new TileLayer({
      title: 'OSM',
      source: new OSM({
        layer: 'OSM'
      }),
    }),
    vectorNode,
    vectorWay
  ]
});

var layersTopoTresc = new LayerGroup({
  layers: [
    new TileLayer({
      title: 'Topotresc',
      source: new XYZ({
        attributions: 'Map data <a href="https://www.topotresc.com/" target="_blank">TopoTresk</a> by <a href="https://github.com/aresta/topotresc" target="_blank">aresta</a>',
        url: 'https://api.topotresc.com/tiles/{z}/{x}/{y}'
      }),
    }),
    vectorNode,
    vectorWay
  ]
});


var map = new Map({
  controls: defaultControls().extend([new FullScreen()]),
  layers: [
    new TileLayer({
      source: new OSM({
        layer: 'OSM'
      })
    }),
    vectorNode,
    vectorWay
  ],
  overlays: [overlay],
  target: 'map',
  view: new View({
    center: fromLonLat([1.9858, 41.9176]),
    zoom: 9
  })
});

//https://openlayers.org/en/latest/examples/popup.html
//https://stackoverflow.com/questions/26391195/adding-event-handler-to-feature-in-openlayers-3
map.on("click", function(e) {
    var coordinate = e.coordinate;
    map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
        //console.log(feature.values_.name);
        content.innerHTML = '<code>' + feature.values_.name + '<br />(' + feature.values_.tipus + ')</code>';
        overlay.setPosition(coordinate);
    })
});

map.on("pointermove", function(e) {
  map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
    if (feature.values_.name != undefined) {
      document.getElementById('tooltip').style.visibility="visible";
      var content = document.getElementById('tooltip-content');
      content.innerHTML = '<code>' + feature.values_.name + '</code>';
      setTimeout(function(){ tancar_tooltip(); }, 2000);
    }
  })
});

function tancar_tooltip() {
  document.getElementById('tooltip').style.visibility = "hidden";
}

function setMapType(newType) {

    if(newType == 'OSM') {
        map.setLayerGroup(layersOSM);
    } else if (newType == 'TRESC') {
        map.setLayerGroup(layersTopoTresc);
    } 
}

//events
var el_osm = document.getElementById("osm");
el_osm.addEventListener("click", function() { setMapType('OSM')}, false);

var el_tresc = document.getElementById("tresc");
el_tresc.addEventListener("click", function() { setMapType('TRESC')}, false);