// al final de tot hi ha un export
function llista_nodes() {
var nodes = [
{
  "type": "node",
  "id": 948712260,
  "lat": 41.6764197,
  "lon": 1.2087574,
  "tags": {
    "ele": "494.00",
    "ign:latitud": "41.6764197106800012",
    "ign:longitud": "1.2087574062149899",
    "ign:red": "Red Geodesica Nacional Convencional (ROI)",
    "man_made": "survey_point",
    "name": "Bruixas",
    "note": "This is a point from an official geodetic network. Use it as a reference, but do NOT move it.",
    "ref": "36120",
    "source": "Instituto Geográfico Nacional",
    "source:ref": "ftp://ftp.geodesia.ign.es"
  }
},
{
  "type": "node",
  "id": 1241569916,
  "lat": 42.0060708,
  "lon": 1.9847071,
  "tags": {
    "name": "Carena del Cingle de la Bruixa",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241630707,
  "lat": 42.4348658,
  "lon": 0.8247161,
  "tags": {
    "name": "la Font de la Bruixa",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241635543,
  "lat": 42.1692574,
  "lon": 1.0994053,
  "tags": {
    "name": "la Bruixa Cremada",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241651210,
  "lat": 42.3021028,
  "lon": 0.8539595,
  "tags": {
    "name": "Clot de la Bruixa",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241689308,
  "lat": 41.6270017,
  "lon": 0.9720283,
  "tags": {
    "name": "la Bruixa",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 3677619167,
  "lat": 42.1665891,
  "lon": 1.7436232,
  "tags": {
    "name": "la Bruixa",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 3867585318,
  "lat": 41.2778128,
  "lon": 1.0658190,
  "tags": {
    "name": "Portell de la Cova de la Bruixa",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 3888698860,
  "lat": 42.1690434,
  "lon": 1.7476551,
  "tags": {
    "name": "Font de la Bruixa",
    "natural": "spring"
  }
},
{
  "type": "node",
  "id": 4058085141,
  "lat": 41.7704651,
  "lon": 2.2400234,
  "tags": {
    "man_made": "water_well",
    "name": "Pou de la Bruixa"
  }
},
{
  "type": "node",
  "id": 5327618375,
  "lat": 41.2900418,
  "lon": 0.8639502,
  "tags": {
    "name": "Cova de la Bruixa",
    "natural": "cave_entrance"
  }
},
{
  "type": "node",
  "id": 5845753932,
  "lat": 41.5907703,
  "lon": 2.1774637,
  "tags": {
    "access": "yes",
    "leisure": "playground",
    "max_age": "9",
    "min_age": "3",
    "name": "Parc de la Bruixa",
    "surface": "sand"
  }
},
{
  "type": "node",
  "id": 6189071516,
  "lat": 41.5403031,
  "lon": 1.3988985,
  "tags": {
    "historic": "stone",
    "name": "el Queixal de la Bruixa",
    "tourism": "attraction"
  }
},
{
  "type": "node",
  "id": 7485830391,
  "lat": 41.2774222,
  "lon": 1.0658594,
  "tags": {
    "name": "Portell de la Cova de la Bruixa",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8239990683,
  "lat": 41.2645963,
  "lon": 1.8301801,
  "tags": {
    "name": "Fondo de la Bruixa",
    "natural": "valley"
  }
},
{
  "type": "node",
  "id": 1260548926,
  "lat": 41.1419424,
  "lon": 0.7597253,
  "tags": {
    "ele": "337.5",
    "name": "l'Ereta de les Bruixes",
    "natural": "peak",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 7930948994,
  "lat": 41.1296475,
  "lon": 0.8958016,
  "tags": {
    "name": "Bosc de Les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 3161338967,
  "lat": 41.8399359,
  "lon": 0.5630668,
  "tags": {
    "ele": "416.0",
    "name": "les Bruixes",
    "natural": "peak",
    "source": "Institut Cartogràfic de Catalunya"
  }
},
{
  "type": "node",
  "id": 1241683141,
  "lat": 41.9811537,
  "lon": 0.6839391,
  "tags": {
    "name": "Pla de les Bruixes",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 8341626780,
  "lat": 42.1954455,
  "lon": 1.0372656,
  "tags": {
    "name": "Planell de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241552374,
  "lat": 41.6267261,
  "lon": 1.5954522,
  "tags": {
    "name": "la Plaça de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241561371,
  "lat": 41.6911280,
  "lon": 1.4051545,
  "tags": {
    "name": "Plans de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 2510615690,
  "lat": 41.6766662,
  "lon": 1.2081012,
  "tags": {
    "ele": "492.2",
    "name": "Tossal de les Bruixes",
    "natural": "peak",
    "source": "Institut Cartogràfic de Catalunya",
    "wikidata": "Q11952793",
    "wikipedia": "ca:Tossal de les Bruixes"
  }
},
{
  "type": "node",
  "id": 2525417637,
  "lat": 41.7249405,
  "lon": 1.2699428,
  "tags": {
    "ele": "463.8",
    "name": "Tossal de les Bruixes",
    "natural": "peak",
    "source": "Institut Cartogràfic de Catalunya"
  }
},
{
  "type": "node",
  "id": 3037180319,
  "lat": 41.7579149,
  "lon": 1.4743585,
  "tags": {
    "ele": "623.7",
    "name": "Collet de les Bruixes",
    "natural": "saddle",
    "source": "Institut Cartogràfic de Catalunya"
  }
},
{
  "type": "node",
  "id": 4154831812,
  "lat": 41.6407358,
  "lon": 1.5714417,
  "tags": {
    "name": "Plaça de les Bruixes",
    "place": "unknown"
  }
},
{
  "type": "node",
  "id": 6212931925,
  "lat": 41.6413356,
  "lon": 1.5829142,
  "tags": {
    "name": "Pla de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241623818,
  "lat": 42.0253059,
  "lon": 1.5301825,
  "tags": {
    "name": "Era de les Bruixes",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 2208453395,
  "lat": 41.8954908,
  "lon": 1.7348541,
  "tags": {
    "ele": "596.4",
    "name": "Coll de les Bruixes",
    "natural": "saddle"
  }
},
{
  "type": "node",
  "id": 5820201871,
  "lat": 41.9889919,
  "lon": 1.2402596,
  "tags": {
    "ele": "532.3",
    "name": "Roc de les Bruixes",
    "natural": "peak",
    "source": "Institut Cartogràfic i Geològic de Catalunya",
    "wikidata": "Q11945860",
    "wikipedia": "ca:Roc de les Bruixes"
  }
},
{
  "type": "node",
  "id": 8445184587,
  "lat": 41.3650233,
  "lon": 2.0007754,
  "tags": {
    "name": "Pla de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 371848124,
  "lat": 41.5893946,
  "lon": 1.8711689,
  "tags": {
    "ele": "389",
    "name": "Coll de les Bruixes",
    "natural": "saddle"
  }
},
{
  "type": "node",
  "id": 1241548708,
  "lat": 41.7535125,
  "lon": 2.3631260,
  "tags": {
    "name": "Serrat de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241556689,
  "lat": 41.6898621,
  "lon": 1.9281076,
  "tags": {
    "name": "les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 2609286856,
  "lat": 41.5805855,
  "lon": 1.8683948,
  "tags": {
    "name": "Pla de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 2610870410,
  "lat": 41.5887963,
  "lon": 1.8678521,
  "tags": {
    "name": "l'Era de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 6590549385,
  "lat": 41.8397668,
  "lon": 2.3613183,
  "tags": {
    "board_type": "history",
    "information": "board",
    "name": "Boscos de bruixes i bandolers",
    "tourism": "information"
  }
},
{
  "type": "node",
  "id": 6898299977,
  "lat": 41.5845783,
  "lon": 1.8679991,
  "tags": {
    "name": "Pla de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7345834346,
  "lat": 41.7074733,
  "lon": 2.2082180,
  "tags": {
    "name": "Plaça de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241567350,
  "lat": 42.0072058,
  "lon": 2.1817448,
  "tags": {
    "name": "Plaça de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241569269,
  "lat": 41.8971437,
  "lon": 1.8591711,
  "tags": {
    "name": "Pla de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241590028,
  "lat": 41.8760839,
  "lon": 2.4222307,
  "tags": {
    "name": "Turó de les Bruixes",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241596173,
  "lat": 42.1559039,
  "lon": 2.3518245,
  "tags": {
    "name": "Serrat de les Bruixes",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 5878346704,
  "lat": 41.9597537,
  "lon": 2.3417821,
  "tags": {
    "historic": "archaeological_site",
    "name": "Pedra de les bruixes"
  }
},
{
  "type": "node",
  "id": 1241591790,
  "lat": 41.8291725,
  "lon": 2.5458333,
  "tags": {
    "name": "Pla de les Bruixes",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241592562,
  "lat": 41.7748603,
  "lon": 2.7067634,
  "tags": {
    "name": "Plaça de les Bruixes",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 5523686940,
  "lat": 41.6366579,
  "lon": 2.5295570,
  "tags": {
    "name": "Pla de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241590639,
  "lat": 41.9052483,
  "lon": 3.0032162,
  "tags": {
    "name": "Saplà de les Bruixes",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241597273,
  "lat": 42.1454992,
  "lon": 3.0050093,
  "tags": {
    "name": "Bosc de les Bruixes",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 2986030039,
  "lat": 41.9688461,
  "lon": 2.9276735,
  "tags": {
    "ele": "348",
    "name": "El balcó de les Bruixes",
    "natural": "peak",
    "tourism": "viewpoint"
  }
},
{
  "type": "node",
  "id": 3677415902,
  "lat": 42.0397974,
  "lon": 2.6319104,
  "tags": {
    "name": "Gorg de les Bruixes",
    "waterway": "waterfall"
  }
},
{
  "type": "node",
  "id": 3752885750,
  "lat": 41.9724780,
  "lon": 3.1136902,
  "tags": {
    "name": "la Plaça de les Bruixes",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7503829441,
  "lat": 41.8995032,
  "lon": 2.6825471,
  "tags": {
    "ele": "285",
    "name": "Turó de les Bruixes",
    "natural": "peak"
  }
},
{
  "type": "node",
  "id": 6736630328,
  "lat": 41.5891719,
  "lon": 1.6212988,
  "tags": {
    "addr:housenumber": "28",
    "addr:postcode": "08700",
    "addr:street": "Carrer de Lecco",
    "name": "Local dels Petits Diables d'Igualada",
    "office": "association"
  }
},
{
  "type": "node",
  "id": 8065644272,
  "lat": 41.7882875,
  "lon": 1.1006953,
  "tags": {
    "amenity": "social_centre",
    "name": "Diables"
  }
},
{
  "type": "node",
  "id": 7486694001,
  "lat": 41.3280439,
  "lon": 2.0875486,
  "tags": {
    "addr:city": "El Prat de Llobregat",
    "addr:housenumber": "20",
    "addr:postcode": "08820",
    "addr:street": "Carrer Ripollès",
    "museum": "local",
    "name": "Colla de Diables del Prat",
    "operator": "Federació d'entitats de Cultura Popular \"El Nus\"",
    "operator:type": "association",
    "tourism": "museum"
  }
},
{
  "type": "node",
  "id": 1241586992,
  "lat": 42.3046775,
  "lon": 3.2726663,
  "tags": {
    "name": "Clot des Dimoni",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241592752,
  "lat": 42.3483229,
  "lon": 2.2952401,
  "tags": {
    "name": "Roca del Dimoni",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 2142735052,
  "lat": 42.0177425,
  "lon": 2.3664781,
  "tags": {
    "name": "salt del gorg dels Dimonis",
    "waterway": "waterfall"
  }
},
{
  "type": "node",
  "id": 4679802376,
  "lat": 41.2950410,
  "lon": 1.9139460,
  "tags": {
    "name": "Avenc del Dimoni",
    "natural": "cave_entrance"
  }
},
{
  "type": "node",
  "id": 5437004085,
  "lat": 41.8465823,
  "lon": 1.4291765,
  "tags": {
    "name": "Roca Endimoniada",
    "natural": "rock"
  }
},
{
  "type": "node",
  "id": 7352731101,
  "lat": 41.3065931,
  "lon": 2.0476162,
  "tags": {
    "name": "Can Dimoni",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241549796,
  "lat": 42.0696149,
  "lon": 2.3224948,
  "tags": {
    "name": "Clot de l'Infernot",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241553899,
  "lat": 41.6376094,
  "lon": 2.6256388,
  "tags": {
    "name": "Serra del Sot de l'Infern",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241559520,
  "lat": 42.0244301,
  "lon": 1.9985116,
  "tags": {
    "name": "Clot de l'Infern",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241568069,
  "lat": 41.4992503,
  "lon": 1.7311257,
  "tags": {
    "name": "l'Infern",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241571072,
  "lat": 41.6308724,
  "lon": 2.6313802,
  "tags": {
    "name": "Sot de l'Infern",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241572375,
  "lat": 41.3504405,
  "lon": 1.8852422,
  "tags": {
    "name": "Bosc d'Infern",
    "natural": "wood"
  }
},
{
  "type": "node",
  "id": 1241591226,
  "lat": 42.2083187,
  "lon": 2.0709255,
  "tags": {
    "name": "Clot de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241591842,
  "lat": 42.1126202,
  "lon": 2.3881427,
  "tags": {
    "name": "Sot de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241594209,
  "lat": 41.8409006,
  "lon": 2.8406435,
  "tags": {
    "name": "Clot de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241598901,
  "lat": 42.4135009,
  "lon": 2.2213941,
  "tags": {
    "name": "Malinfern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241599043,
  "lat": 42.3848859,
  "lon": 2.1977060,
  "tags": {
    "name": "Clot de Malinfern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241599070,
  "lat": 42.0728966,
  "lon": 2.4818831,
  "tags": {
    "name": "Clot de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241600633,
  "lat": 42.4228376,
  "lon": 3.0925810,
  "tags": {
    "name": "Comes de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241610708,
  "lat": 42.2015754,
  "lon": 1.1024219,
  "tags": {
    "name": "Forat de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241634271,
  "lat": 42.6643528,
  "lon": 0.7341263,
  "tags": {
    "name": "Clòt der Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241637075,
  "lat": 42.6528976,
  "lon": 0.6970802,
  "tags": {
    "name": "Clòts der Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241645667,
  "lat": 42.4656164,
  "lon": 0.9819652,
  "tags": {
    "name": "Costa d'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241647231,
  "lat": 42.3725158,
  "lon": 1.5036048,
  "tags": {
    "name": "Costa de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241655291,
  "lat": 42.3536262,
  "lon": 1.4154551,
  "tags": {
    "name": "els Inferns",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241678823,
  "lat": 41.8258954,
  "lon": 0.6034059,
  "tags": {
    "name": "Clot de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241681063,
  "lat": 41.7089597,
  "lon": 1.0778087,
  "tags": {
    "name": "Clot de l'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241691572,
  "lat": 41.5817514,
  "lon": 0.8703556,
  "tags": {
    "name": "els Inferns",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241786451,
  "lat": 40.9187495,
  "lon": 0.4300373,
  "tags": {
    "name": "la Vall d'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241793434,
  "lat": 41.3742316,
  "lon": 1.4828866,
  "tags": {
    "name": "Vall d'Infern",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1996185284,
  "lat": 42.3175527,
  "lon": 3.3192458,
  "tags": {
    "image": "https://commons.wikimedia.org/wiki/File:Cova_de_d'Infern,_Cap_de_Creus_(novembre_2012)_-_panoramio_(1).jpg",
    "name": "Mirador de la Cova de s'Infern",
    "tourism": "viewpoint",
    "wikimedia_commons": "File:Cova_de_d'Infern,_Cap_de_Creus_(novembre_2012)_-_panoramio_(1).jpg"
  }
},
{
  "type": "node",
  "id": 1996188652,
  "lat": 42.3173827,
  "lon": 3.3197283,
  "tags": {
    "name": "Cova de s'Infern",
    "natural": "cave_entrance"
  }
},
{
  "type": "node",
  "id": 3860980597,
  "lat": 41.2786152,
  "lon": 1.8252590,
  "tags": {
    "ele": "203.5",
    "name": "Coll de l'Infern",
    "natural": "saddle"
  }
},
{
  "type": "node",
  "id": 4686524856,
  "lat": 41.2948401,
  "lon": 1.9148779,
  "tags": {
    "name": "Avenc de l'Infern",
    "natural": "cave_entrance"
  }
},
{
  "type": "node",
  "id": 4959709319,
  "lat": 42.3039716,
  "lon": 3.2509800,
  "tags": {
    "ele": "384.2",
    "name": "Puig de l'Infern",
    "natural": "peak",
    "source": "Institut Cartogràfic i Geològic de Catalunya"
  }
},
{
  "type": "node",
  "id": 5024807933,
  "lat": 41.2798770,
  "lon": 1.8236486,
  "tags": {
    "ele": "209.2",
    "name": "Puig del Fondo de l'Infern",
    "natural": "peak",
    "source": "Institut Cartogràfic i Geològic de Catalunya"
  }
},
{
  "type": "node",
  "id": 5517233626,
  "lat": 41.6341604,
  "lon": 2.6295126,
  "tags": {
    "name": "Sot de l'Infern",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 6168563600,
  "lat": 41.5944983,
  "lon": 0.8698785,
  "tags": {
    "ele": "300.2",
    "name": "Tossal de l'Infern",
    "natural": "peak",
    "source": "Institut Cartogràfic i Geològic de Catalunya",
    "wikidata": "Q11952744",
    "wikipedia": "ca:Tossal de l'Infern"
  }
},
{
  "type": "node",
  "id": 6347711837,
  "lat": 41.7775401,
  "lon": 2.2239077,
  "tags": {
    "name": "Salt de l'Infern",
    "waterway": "waterfall"
  }
},
{
  "type": "node",
  "id": 7095910086,
  "lat": 41.5278453,
  "lon": 2.3815548,
  "tags": {
    "ele": "323.8",
    "name": "Turó de l'Infern",
    "natural": "peak",
    "source": "Institut Cartogràfic i Geològic de Catalunya",
    "wikidata": "Q17483784",
    "wikipedia": "ca:Turó de l'Infern"
  }
},
{
  "type": "node",
  "id": 7124560401,
  "lat": 41.9986749,
  "lon": 2.5839710,
  "tags": {
    "name": "Esquerda de l'Infern",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 8024824187,
  "lat": 40.9079975,
  "lon": 0.4315226,
  "tags": {
    "name": "Font de Vall de l'Infern",
    "natural": "spring"
  }
},
{
  "type": "node",
  "id": 8239990700,
  "lat": 41.2785747,
  "lon": 1.8218542,
  "tags": {
    "name": "Fondo de l'Infern",
    "natural": "valley"
  }
},
{
  "type": "node",
  "id": 1774479483,
  "lat": 42.42369,
  "lon": 2.21377,
  "tags": {
    "ele": "2869.5",
    "name": "Pic de l'Infern",
    "natural": "peak"
  }
},
{
  "type": "node",
  "id": 1241566529,
  "lat": 41.6558916,
  "lon": 2.0537803,
  "tags": {
    "name": "Cova del Drac",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1539507255,
  "lat": 41.6457558,
  "lon": 2.0150886,
  "tags": {
    "ele": "980.2",
    "name": "Morral del Drac",
    "natural": "peak",
    "prominence": "19",
    "summit:cross": "yes",
    "tourism": "attraction"
  }
},
{
  "type": "node",
  "id": 1716858622,
  "lat": 41.4000906,
  "lon": 2.1228665,
  "tags": {
    "amenity": "kindergarten",
    "name": "Drac de Sarrià"
  }
},
{
  "type": "node",
  "id": 2046001950,
  "lat": 41.6405197,
  "lon": 2.4000419,
  "tags": {
    "historic": "monument",
    "name": "Drac Alat"
  }
},
{
  "type": "node",
  "id": 2799097987,
  "lat": 41.5568360,
  "lon": 2.0394285,
  "tags": {
    "historic": "monument",
    "name": "Drac"
  }
},
{
  "type": "node",
  "id": 2905418429,
  "lat": 41.6461662,
  "lon": 2.0143739,
  "tags": {
    "direction": "SE",
    "name": "Mirador del Morral del Drac",
    "tourism": "viewpoint"
  }
},
{
  "type": "node",
  "id": 2984338267,
  "lat": 41.3175656,
  "lon": 2.0860468,
  "tags": {
    "historic": "memorial",
    "name": "Drac"
  }
},
{
  "type": "node",
  "id": 5056793248,
  "lat": 42.2662629,
  "lon": 2.9646574,
  "tags": {
    "artist_name": "Mercè Riba",
    "artwork_type": "sculpture",
    "name": "Sant Jordi i el Drac",
    "start_date": "2011",
    "tourism": "artwork"
  }
},
{
  "type": "node",
  "id": 5168232323,
  "lat": 41.3826568,
  "lon": 2.1741405,
  "tags": {
    "amenity": "restaurant",
    "name": "El Drac de Sant Jordi",
    "name:ca": "El Drac de Sant Jordi"
  }
},
{
  "type": "node",
  "id": 5480122868,
  "lat": 41.5928394,
  "lon": 2.5725574,
  "tags": {
    "historic": "memorial",
    "name": "Font del drac"
  }
},
{
  "type": "node",
  "id": 6897675488,
  "lat": 41.3611837,
  "lon": 1.6683988,
  "tags": {
    "amenity": "school",
    "name": "Escola El Drac"
  }
},
{
  "type": "node",
  "id": 6897683986,
  "lat": 41.3608941,
  "lon": 1.6678519,
  "tags": {
    "amenity": "kindergarten",
    "name": "Llar d'infants El Petit Drac",
    "opening_hours": "Mo-Fr 08:00-17:00"
  }
},
{
  "type": "node",
  "id": 3583154850,
  "lat": 41.6195833,
  "lon": 1.9701833,
  "tags": {
    "name": "Avenc del Torrent Maleït",
    "natural": "cave_entrance"
  }
},
{
  "type": "node",
  "id": 4252339778,
  "lat": 42.631073,
  "lon": 0.65664567,
  "tags": {
    "ele": "3404",
    "name": "la Maleïda (Aneto)",
    "natural": "peak"
  }
},
{
  "type": "node",
  "id": 8740459731,
  "lat": 42.31726,
  "lon": 3.32067,
  "tags": {
    "name": "Punta de s'Infern",
    "natural": "cape"
  }
},
{
  "type": "node",
  "id": 4935202155,
  "lat": 42.31726,
  "lon": 3.32067,
  "tags": {
    "name": "Puig de s'Infern",
    "natural": "peak"
  }
},
{
  "type": "node",
  "id": 8886914868,
  "lat": 42.03972,
  "lon": 2.63182,
  "tags": {
    "name": "Salt de les Bruixes",
    "natural": "waterfall"
  }
},
{
  "type": "node",
  "id": 5543026915,
  "lat": 42.13951,
  "lon": 2.09875,
  "tags": {
    "name": "Collet de les Bruixes",
    "natural": "saddle"
  }
},
{
  "type": "node",
  "id": 1241556475,
  "lat": 42.0476266,
  "lon": 2.0888479,
  "tags": {
    "name": "Serrat del Diable",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 1241634648,
  "lat": 42.2389847,
  "lon": 1.3324222,
  "tags": {
    "name": "la Pixarrada del Diable",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1241762710,
  "lat": 41.1448535,
  "lon": 1.2588846,
  "tags": {
    "name": "Comellar del Diable",
    "place": "locality",
    "source": "Instituto Geográfico Nacional",
    "source:date": "201011"
  }
},
{
  "type": "node",
  "id": 1265973336,
  "lat": 41.5805966,
  "lon": 2.1799847,
  "tags": {
    "historic": "archaeological_site",
    "megalith_type": "menhir",
    "name": "Menhir de Pedra Llarga o de la Pedra del Diable",
    "site_type": "megalith"
  }
},
{
  "type": "node",
  "id": 2142760737,
  "lat": 41.1453325,
  "lon": 1.2423528,
  "tags": {
    "name": "Pont del Diable",
    "tourism": "viewpoint"
  }
},
{
  "type": "node",
  "id": 2159556561,
  "lat": 41.6075427,
  "lon": 1.7781171,
  "tags": {
    "historic": "archaeological_site",
    "name": "Megàlit de la Diablera"
  }
},
{
  "type": "node",
  "id": 2549113122,
  "lat": 41.1446286,
  "lon": 1.2439223,
  "tags": {
    "highway": "motorway_junction",
    "name": "àrea de descans Pont del Diable",
    "name:ca": "àrea de descans Pont del Diable",
    "name:es": "área de descanso Puente del Diablo",
    "noref": "yes"
  }
},
{
  "type": "node",
  "id": 2725458516,
  "lat": 41.5435861,
  "lon": 1.5281699,
  "tags": {
    "name": "Cova del Diable",
    "natural": "cave_entrance"
  }
},
{
  "type": "node",
  "id": 3390740199,
  "lat": 41.5250314,
  "lon": 2.3297511,
  "tags": {
    "historic": "archaeological_site",
    "megalith_type": "menhir",
    "name": "Menhir de la Pedra del Diable",
    "site_type": "megalith",
    "wikidata": "Q21402431",
    "wikipedia": "ca:Menhir de la Pedra del Diable"
  }
},
{
  "type": "node",
  "id": 3452004525,
  "lat": 41.6595629,
  "lon": 2.5187071,
  "tags": {
    "ele": "374",
    "name": "Roca del Diable",
    "natural": "peak"
  }
},
{
  "type": "node",
  "id": 4140286113,
  "lat": 41.0973522,
  "lon": 0.9375764,
  "tags": {
    "name": "Escaletes del Diable",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 4523557313,
  "lat": 41.6250699,
  "lon": 2.4184083,
  "tags": {
    "ele": "383.9",
    "name": "Roca del Diable",
    "natural": "peak",
    "source": "Institut Cartogràfic i Geològic de Catalunya"
  }
},
{
  "type": "node",
  "id": 4838387910,
  "lat": 41.1458470,
  "lon": 1.2429170,
  "tags": {
    "name": "Barranc del Diable",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 4838673153,
  "lat": 41.5199205,
  "lon": 1.5637313,
  "tags": {
    "name": "Gorg del Diable",
    "waterway": "waterfall",
    "natural": "yes"
  }
},
{
  "type": "node",
  "id": 5095138227,
  "lat": 41.3458855,
  "lon": 1.2538771,
  "tags": {
    "name": "Pont del Diable",
    "tourism": "attraction"
  }
},
{
  "type": "node",
  "id": 5412470636,
  "lat": 41.5680867,
  "lon": 1.7280101,
  "tags": {
    "name": "Font de la Teula (o del Diable)",
    "natural": "spring"
  }
},
{
  "type": "node",
  "id": 5537371793,
  "lat": 40.9521801,
  "lon": 0.5881944,
  "tags": {
    "name": "la Cassola del Diable",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 5889524737,
  "lat": 42.3570657,
  "lon": 0.9226738,
  "tags": {
    "name": "Pont del Diable",
    "tourism": "attraction"
  }
},
{
  "type": "node",
  "id": 5889873120,
  "lat": 42.0988889,
  "lon": 2.8019444,
  "tags": {
    "description": "Sense garanties sanitàries",
    "drinking_water": "yes",
    "drinking_water:legal": "no",
    "intermittent": "no",
    "name": "Font del Diable",
    "natural": "spring",
    "refitted": "yes",
    "source": "extrapolation from \"Les fonts del Pla de l'Estany\" and PNOA",
    "source:name": "local knowledge"
  }
},
{
  "type": "node",
  "id": 6003490286,
  "lat": 41.4749854,
  "lon": 1.9374518,
  "tags": {
    "bridge:support": "pier",
    "material": "stone",
    "name": "Pont del Diable"
  }
},
{
  "type": "node",
  "id": 6399054488,
  "lat": 41.1478997,
  "lon": 1.2545691,
  "tags": {
    "name": "Bosc del Diable",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 6699849535,
  "lat": 41.3699878,
  "lon": 1.1416271,
  "tags": {
    "name": "Coma del diable",
    "place": "locality"
  }
},
{
  "type": "node",
  "id": 7234322188,
  "lat": 42.1522200,
  "lon": 2.5977800,
  "tags": {
    "historic": "archaeological_site",
    "megalith_type": "stone",
    "name": "Pedra del Diable",
    "site_type": "megalith",
    "wikidata": "Q21730804",
    "wikipedia": "ca:Pedra del Diable (Santa Pau)"
  }
},
{
  "type": "node",
  "id": 7444481123,
  "lat": 41.0472026,
  "lon": 0.8739637,
  "tags": {
    "name": "Cova del Diable",
    "natural": "cave_entrance"
  }
},
{
  "type": "node",
  "id": 7680813641,
  "lat": 41.6256483,
  "lon": 2.4201626,
  "tags": {
    "name": "La Roca del Diable",
    "natural": "rock"
  }
},
{
  "type": "node",
  "id": 7878365251,
  "lat": 41.5973672,
  "lon": 1.8304569,
  "tags": {
    "name": "Cadireta del Diable",
    "natural": "peak"
  }
},
{
  "type": "node",
  "id": 8321271906,
  "lat": 42.0977691,
  "lon": 2.5633989,
  "tags": {
    "name": "Pont del Diable",
    "natural": "stone"
  }
},
{
  "type": "node",
  "id": 8479278547,
  "lat": 41.4218959,
  "lon": 1.3757886,
  "tags": {
    "name": "Obaga del Diable",
    "place": "locality"
  }
}
];
return nodes;
}

export default llista_nodes;